import React from 'react';
import { FaUniversity, FaListAlt, FaChartLine } from 'react-icons/fa';
import { IoLibraryOutline } from 'react-icons/io5';

const Stats = () => {
    // Standardize blue shades
    const blueShade1 = 'text-blue-600'; // Base color for the icons and text
    const blueShade2 = 'bg-blue-100'; // Slightly lighter for background
    const blueShade3 = 'bg-blue-50'; // Lightest for backgrounds

    return (
        <div className="mx-auto md:max-w-xl lg:max-w-screen-xl grid gap-8 grid-cols-2 lg:grid-cols-4">
            {/* Universities Stat */}
            <div className="text-center">
                <div className={`flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full ${blueShade3} shadow-md`}>
                    <FaUniversity className={`w-8 h-8 sm:w-10 sm:h-10 ${blueShade1}`} />
                </div>
                <h6 className={`text-base sm:text-2xl lg:text-4xl font-bold ${blueShade1}`}>200+</h6>
                <p className="text-sm mb-2 font-semibold text-gray-700">Universities</p>
            </div>

            {/* Checklist Steps Stat */}
            <div className="text-center">
                <div className={`flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full ${blueShade2} shadow-md`}>
                    <FaListAlt className={`w-8 h-8 sm:w-10 sm:h-10 ${blueShade1}`} />
                </div>
                <h6 className={`text-base sm:text-2xl lg:text-4xl font-bold ${blueShade1}`}>60+</h6>
                <p className="text-sm mb-2 font-semibold text-gray-700">Checklist Steps</p>
            </div>

            {/* Resources Stat */}
            <div className="text-center">
                <div className={`flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full ${blueShade2} shadow-md`}>
                    <IoLibraryOutline className={`w-8 h-8 sm:w-10 sm:h-10 ${blueShade1}`} />
                </div>
                <h6 className={`text-base sm:text-2xl lg:text-4xl font-bold ${blueShade1}`}>30+</h6>
                <p className="text-sm mb-2 font-semibold text-gray-700">Resources</p>
            </div>

            {/* Track Your Journey Stat */}
            <div className="text-center">
                <div className={`flex items-center justify-center w-16 h-16 mx-auto mb-3 rounded-full ${blueShade3} shadow-md`}>
                    <FaChartLine className={`w-8 h-8 sm:w-10 sm:h-10 ${blueShade1}`} />
                </div>
                <h6 className={`text-base sm:text-2xl lg:text-4xl font-bold ${blueShade1}`}>100%</h6>
                <p className="text-sm mb-2 font-semibold text-gray-700">Track Your Journey</p>
            </div>
        </div>
    );
}

export default Stats;
