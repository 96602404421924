import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Home from './pages/Home';
import Programs from './pages/Programs';
import Error from './pages/Error';
import Company from './pages/Company';
// import CheckList from './components/CheckList';
import TaskDetails from './components/TaskDetails';
import Profile from './components/Profile';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import ScrollToTop from './pages/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <NavBar />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about' element={<Company />} />
        <Route path='/profile' element={<Profile />} />
        <Route path='/programs' element={<Programs />} />
        {/* <Route path='/checklist' element={<CheckList />} /> */}
        <Route path='/task/:id' element={<TaskDetails />} />
        <Route path='*' element={<Error />} />
      </Routes>
      <Footer />
    </Router>
  )
}

export default App;