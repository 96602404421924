import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TaskService from "../services/TaskService";
import UserService from "../services/UserService";

const Checklist = () => {
    const [parentTasks, setParentTasks] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedUniversity, setSelectedUniversity] = useState(null);
    const navigate = useNavigate();

    // Fetching tasks
    useEffect(() => {
        const taskService = new TaskService();
        const fetchTasks = async () => {
            try {
                const fetchedParentTasks = await taskService.getParentTasks();
                setParentTasks(fetchedParentTasks || []);
            } catch (error) {
                console.error('Error fetching parent tasks:', error);
                setParentTasks([]);
            } finally {
                setIsLoading(false);
            }
        };
        fetchTasks();
    }, [selectedUniversity]);

    // Fetching user universities
    useEffect(() => {
        const fetchUniversities = async () => {
            try {
                const universityData = await UserService.getUserUniversities();
                setSelectedUniversity(universityData[0]);
            } catch (error) {
                console.error('Error fetching university data:', error);
            }
        };
        fetchUniversities();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const completedTasksCount = parentTasks.filter(task => task.completed).length;
    const totalTasks = parentTasks.length;
    const completionPercentage = totalTasks > 0 ? Math.round((completedTasksCount / totalTasks) * 100) : 0;

    return (
        <div className="w-full px-4 mt-24">
            {/* Title Section */}
            <div className="bg-gradient-to-r from-blue-50 via-white to-blue-50 text-center py-20">
                <h1 className="text-5xl md:text-6xl font-extrabold text-gray-900 leading-tight">
                    Track Your <span className="text-blue-600">Progress</span> <br className="hidden md:inline-block"/> & Crush Your <span className="text-blue-600">Checklist</span>
                </h1>
                <p className="text-xl md:text-2xl text-gray-500 mt-6 max-w-xl mx-auto">
                    Make every step count on your journey to success with seamless tracking and easy task management.
                </p>
            </div>

            {/* User Profile Section */}
            <div className="flex flex-col items-center justify-center w-full py-20">
                <div className="flex items-center justify-center">
                    <div className="avatar bg-gray-200 rounded-full flex items-center justify-center w-12 h-12">
                        <span className="text-gray-500 text-xs">50 x 50</span> {/* Placeholder for user image */}
                    </div>
                    <div className="ml-4">
                        <h2 className="text-lg font-semibold">Sanket Gattani</h2>
                        <p className="text-sm text-gray-600">MS CS Student at University at Buffalo</p>
                    </div>
                </div>

                {/* Progress Bar */}
                <div className="w-full bg-gray-300 rounded-full h-2 mt-4">
                    <div
                        className="bg-blue-600 h-2 rounded-full transition-all duration-500 ease-in-out"
                        style={{ width: `${completionPercentage}%` }}
                    ></div>
                </div>
                <p className="text-blue-600 font-semibold mt-2">{completionPercentage}% Complete</p>
            </div>
         
            {/* Tasks Section */}
            <div className="w-full text-center mx-auto">
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-8 w-full">
                    {parentTasks.map(task => (
                        <div
                            key={task.taskId}
                            className={`relative bg-white p-6 shadow-lg rounded-lg flex flex-col items-center transition transform hover:scale-105 min-h-[300px] min-w-[200px] ${task.completed ? 'opacity-50' : ''}`}
                            onClick={() => navigate(`/task/${task.taskId}`, { state: { taskId: task.taskId, taskName: task.taskName, videoUrl: task.assetUrl } })}
                            style={{ cursor: 'pointer' }}
                        >
                            {/* Removed circle background and enlarged the image */}
                            <div className="task-icon mb-3 w-full h-60 flex items-center justify-center">
                                <img
                                    src={task.iconUrl}
                                    alt={task.taskName}
                                    className="w-full h-full object-contain"
                                />
                            </div>
                            <h4 className="text-lg font-semibold text-blue-600 text-center">{task.taskName}</h4>

                            {/* Mark Complete / Undo Button */}
                            <div className="mt-auto"> {/* Ensures the button is pushed to the bottom */}
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent task click trigger
                                        setParentTasks(parentTasks.map(t => t.taskId === task.taskId ? { ...t, completed: !t.completed } : t));
                                    }}
                                    className={`mt-4 py-2 px-4 rounded-lg text-white transition ${
                                        task.completed ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
                                    }`}
                                >
                                    {task.completed ? "Undo" : "Mark Complete"}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Checklist;
