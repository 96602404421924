import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypewriter, Cursor } from 'react-simple-typewriter';

// Hero image import
import hero from '../../src/assets/programs/hero.jpg';

const AboutUs = () => {
  const navigate = useNavigate(); // Use navigate for routing

  const [text] = useTypewriter({
    words: ['built by students.', 'for student benefits.', 'designed to support your journey.'],
    loop: {},
    typeSpeed: 120,
    deleteSpeed: 80,
  });

  const handleButtonClick = () => {
    navigate('/'); // Navigate to the checklist page
  };

  return (
    <section className="overflow-hidden">
      <div className="px-10 mx-auto py-44 sm:px-20">
        <div className="flex flex-wrap -m-8">
          {/* Left side with text */}
          <div className="w-full md:w-1/2 p-8">
            <div className="md:max-w-lg">
              <h1 className="text-4xl font-semibold tracking-tight text-gray-800 sm:text-5xl">
                Prep4USA is <span className='text-primary shadow-md'>{text}</span>
                <span className='text-gray-800'><Cursor cursorStyle='|' /></span>
              </h1>
              <p className="mt-6 text-sm text-gray-700 font-medium lg:text-base mb-6 text-center sm:text-left">
                We’re a student-led community offering guidance, resources, and support for international students heading to the USA. Built by students who’ve been through the process, we’re here to help you after you've secured your university admission.
              </p>
              <p className="mt-4 text-sm text-gray-700 font-medium lg:text-base mb-6 text-center sm:text-left">
                Our community is here to ensure you have all the tools, peer support, and knowledge to successfully start your educational journey in the USA.
              </p>
              <div className="text-center sm:text-left">
                <button
                  onClick={handleButtonClick}
                  className="inline-block px-5 py-4 text-white font-semibold tracking-tight bg-primary hover:bg-primary-hover rounded-lg focus:ring-4 focus:ring-primary transition duration-200"
                >
                  Begin Your Journey to USA
                </button>
              </div>
            </div>
          </div>

          {/* Right side with big hero image */}
          <div className="w-full md:w-1/2 p-8">
            <img
              className="object-cover object-center mx-auto shadow-2xl rounded-2xl sm:rounded-3xl"
              alt="hero"
              src={hero}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;