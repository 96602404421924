import React from 'react'

//components
import ProgramHero from '../components/ProgramHero'
// import WhyPrograms from '../components/WhyPrograms'
// import Benefits from '../components/Benefits'
import Testimonial from '../components/Testimonial'
// import PCTA from '../components/PCTA'
import ContactUs from '../components/ContactUs'

const Programs = () => {
    return (
        <>
            <div className='mb-36 space-y-40'>
                <ProgramHero />
                <Testimonial />
                <ContactUs/>
            </div>
        </>
    )
}

export default Programs