// export default NavBar
import React, { useState, useEffect } from 'react';
import { Dialog, Popover } from '@headlessui/react';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { FaArrowRight } from 'react-icons/fa';

// images
import brandicon from '../assets/preplogo.png';

// Firebase config
import { auth, googleProvider } from '../config/FirebaseConfig';
import UserService from '../services/UserService';

// icons
import {
    Bars3Icon,
} from '@heroicons/react/24/outline';


const NavBar = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    useEffect(() => {
        // Check if user is logged in
        const checkIsLoggedIn = async () => {
            try {
                const loggedIn = await UserService.isUserLoggedIn();
                setIsLoggedIn(loggedIn);
            } catch (error) {
                console.error("Error checking login status: ", error);
                setIsLoggedIn(false); // Set to false if there is an error
            }
        };
    
        checkIsLoggedIn(); // Call the function
    }, []);

    // Function to handle user logout
function logout(navigate) {
    setIsLoggedIn(false);
    UserService.signOut();
    navigate("/");
}

// Function to handle Google sign-in
function signInWithGoogle(navigate) {
    signInWithPopup(auth, googleProvider)
        .then((result) => {
            const credential = GoogleAuthProvider.credentialFromResult(result);
            if (credential) {
                const token = credential.idToken;
                const user = {
                    userId: result.user.uid,
                    displayName: result.user.displayName,
                    email: result.user.email,
                    gender: result.user.gender || "",
                    photoURL: result.user.photoURL,
                    creationTime: ""
                }

                // console.log("user data", user);
                // console.log('Google Access Token:', token);
                // console.log('Profile Data:', result.user);

                if(UserService.signInWithGoogle(token, user)){
                    setIsLoggedIn(true);
                    navigate("/profile");
                }

            } else {
                console.log('No credential available.');
            }
        })
        .catch((error) => {
            console.error('Error during sign-in:', error);
        });
}

    return (
        <div className='bg-white'>
            <header className='absolute inset-x-0 top-0 z-50'>
                <nav className="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
                    <div className="flex lg:flex-1">
                        <Link to="/" className="-m-1.5 p-1.5">
                            <span className="sr-only">Prep4USA</span>
                            <img className="h-12 w-auto lg:hidden" src={brandicon} alt="preplogo" />
                            <img className="hidden h-12 w-auto lg:block" src={brandicon} alt="preplogo" />
                        </Link>
                    </div>
                    <div className="flex lg:hidden">
                        <button
                            type="button"
                            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                            onClick={() => setMobileMenuOpen(true)}
                        >
                            <span className="sr-only">Open main menu</span>
                            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                        </button>
                    </div>
                    <Popover.Group className="hidden lg:flex lg:gap-x-12">
                        <Link to="/" className="text-sm font-semibold leading-6 text-gray-700 hover:text-gray-500 duration-300 transition-colors">
                            Home
                        </Link>
                        <Link to="/profile" className="text-sm font-semibold leading-6 text-gray-700 hover:text-gray-500 duration-300 transition-colors">
                            Profile
                        </Link>
                        <Link to="/programs" className="text-sm font-semibold leading-6 text-gray-700 hover:text-gray-500 duration-300 transition-colors">
                            About Us
                        </Link>
                    </Popover.Group>
                    <div className="hidden lg:flex lg:flex-1 lg:justify-end">
                        {isLoggedIn ? (
                            <button className="btn flex gap-1 items-center" onClick={() => logout(navigate)}>
                            Logout
                            </button>
                            ) : (
                                <button className="btn flex gap-1 items-center" onClick={() => signInWithGoogle(navigate)}>
                                Login <FaArrowRight size={15} />
                                </button>
                        )}
                    </div>
                </nav>

                {/* Responsive Menu */}
                <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={() => setMobileMenuOpen(false)}>
                    <div className="fixed inset-0 z-10" />
                    <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
                        <div className="flex items-center justify-between">
                            <Link to="/" className="-m-1.5 p-1.5">
                                <span className="sr-only">Prep4USA</span>
                            </Link>
                            <button
                                type="button"
                                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                                onClick={() => setMobileMenuOpen(false)}
                            >
                                <span className="sr-only">Close menu</span>
                            </button>
                        </div>
                        <div className="mt-16 flow-root">
                            <div className="-my-6 divide-y divide-gray-500/10">
                                <div className="space-y-2 py-6">
                                    <Link
                                        to="/"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-700 hover:bg-gray-50"
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        Home
                                    </Link>
                                    <Link
                                        to="/profile"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-700 hover:bg-gray-50"
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        Profile
                                    </Link>
                                    <Link
                                        to="/about"
                                        className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-700 hover:bg-gray-50"
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        About Us
                                    </Link>
                                </div>
                                <div className="py-6">
                                {isLoggedIn ? (
                            <button className="btn flex gap-1 items-center" onClick={() => logout(navigate)}>
                            Logout
                            </button>
                            ) : (
                                <button className="btn flex gap-1 items-center" onClick={() => signInWithGoogle(navigate)}>
                                Login <FaArrowRight size={15} />
                                </button>
                        )}
                                </div>
                            </div>
                        </div>
                    </Dialog.Panel>
                </Dialog>
            </header>
        </div>
    );
};

export default NavBar;
